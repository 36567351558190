import React, { Fragment, useEffect, useState } from 'react'
// import ReactLoading from 'react-loading'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import clsx from 'clsx'

import LinearBuffer from './LinearProgress'
import { getSettings } from '@/utils/session'
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #fff',
    height: '120px',
    width: '120px',
    // borderRadius: '50%',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    position: 'relative',
    marginLeft: '-100px',
  },
}))

const CircularProgress = ({ className, redirect }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const [settings, setSettings] = useState({})

  useEffect(() => {
    if(typeof window != 'undefined'){
       setSettings(getSettings())
    }
  }, []);

  const progressStyle = {
    background: `url('${settings['app.logo.image.loading']}')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '110px',
    height: '110px',
    backgroundPosition: 'center'
  };

  return (
    <Fragment>
      <div className={`loader ${className}`}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className={clsx(
                  classes.paper,
                  'rotation-animation-progress-hleads'
                )}
              >
                <div style={progressStyle}></div>
              </div>
              <div className={classes.textContainer}>
                <span className={'loading-text-progress'}>
                  {redirect ? <LinearBuffer /> : 'Loading please wait...'}
                </span>
              </div>
            </div>
          </Fade>
        </Modal>

        {/*<svg className="circular" viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="15"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      </svg>*/}
      </div>
    </Fragment>
  )
}
export default CircularProgress
