/* eslint-disable react/display-name */
/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import AppLocale from '../../lngProvider'

export default (ComposedComponent) => (props) => {
  const { locale } = useSelector(({ settings }) => settings)
  const [currentAppLocale, setCurrentAppLocale] = React.useState(
    AppLocale('localhost')[locale.locale]
  )
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // const extractDomain = require('extract-domain')
    const domain = window.location.hostname
    setCurrentAppLocale(AppLocale(domain, {})[locale.locale])
  }, [locale])
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ComposedComponent {...props} />
    </IntlProvider>
  )
}
