/* eslint-disable react/display-name */
import React from 'react'
import Layout from '../../core/DefaultLayout'

export default (ComposedComponent) => (props) =>
  (
    <Layout>
      <ComposedComponent {...props} />
    </Layout>
  )
