import { Box, LinearProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useEffect, useRef, useState } from 'react'

const StyledLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 4,
    width: 700,
    // marginLeft: '-60px'
  },
})(LinearProgress)
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: '', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <StyledLinearProgress variant="buffer" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.white">
          {/* {`${Math.round(props.value)}%  Redirecting`}{' '} */}
          <span
            className="text-nowrap"
            style={{
              fontSize: '1.5rem',
              // marginLeft: '-15rem',
              transition: 'ease',
            }}
          >
            {props.text.split('by')[0]}
          </span>
          <span> {props.text.split('by')[1]}</span>
        </Typography>
      </Box>
    </Box>
  )
}

export default function LinearBuffer() {
  const [progress, setProgress] = useState(0)
  const [buffer, setBuffer] = useState(10)
  const [text, setText] = useState(
    'Take a big breath. The email campaign will load very soon'
  )

  const progressRef = useRef(() => {})
  useEffect(() => {
    progressRef.current = () => {
      if (progress >= 100) {
        setProgress(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setProgress(progress + diff)
        setBuffer(progress + diff + diff2)
      }
    }
  })

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current()
    }, 700)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    const text = setInterval(() => {
      setText(
        '“Whether it’s marriage or business, patience is the first rule of success.” by William Feather'
      )
    }, 4000)

    return () => {
      clearInterval(text)
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LinearProgressWithLabel
        variant="buffer"
        value={progress > 100 ? 100 : progress}
        valueBuffer={buffer}
        text={text}
      />
    </Box>
  )
}
