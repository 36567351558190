import React from 'react'
import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { IntlProvider } from 'react-intl'

import blueTheme from '../themes/blueTheme'

import AppLocale from '../../../lngProvider'
// import { GET_TRANSLATION } from '../../../constants/ActionTypes'

class App extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isDarkTheme || nextProps.darkTheme) {
      document.body.classList.add('dark-theme')
    } else {
      document.body.classList.remove('dark-theme')
    }

    if (nextProps.isDirectionRTL) {
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl')
    }
  }
  state = {
    domain: '',
  }

  componentDidMount() {
    document.body.classList.remove('dark-theme')
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // const extractDomain = require('extract-domain')
    const domain = window.location.hostname

    this.setState({ domain })
    // this.props.dispatch({ type: GET_TRANSLATION, payload: { domain } })
  }
  render() {
    const { children, serverTranslation } = this.props

    let applyTheme = createTheme(blueTheme)
    const currentAppLocale = AppLocale(this.state.domain, serverTranslation)[
      'en'
    ]

    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            {children}
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    darkTheme,
    isDirectionRTL,
    themeColor,
    navStyle,
    locale,
    serverTranslation,
  } = settings
  return {
    isDarkTheme: darkTheme,
    isDirectionRTL,
    themeColor,
    navStyle,
    locale,
    serverTranslation,
  }
}
export default withRouter(connect(mapStateToProps)(App))
