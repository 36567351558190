import blue from '@material-ui/core/colors/blue'
import deepOrange from '@material-ui/core/colors/deepOrange'
import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme()

export default {
  breakpoints: {
    values: Object.assign({}, defaultTheme.breakpoints.values, { xs: 820 }),
  },

  palette: {
    primary: {
      light: blue[200],
      main: blue[500],
      dark: blue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: deepOrange[300],
      main: deepOrange['A200'],
      dark: deepOrange[700],
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
}
